import Router from './Router';
import { auth } from "./Firebase";


const Auth = {

    onAuthStateChanged: (cb) => {
        auth.onAuthStateChanged(cb);
    },

    signIn: (form) => {
        const email = form[0].value;
        const passw = form[1].value;
        auth.signInWithEmailAndPassword(email, passw).catch(function(error) {
            console.error(error.code, error.message);
            form[0].setCustomValidity("Invalid field.");
            form[1].setCustomValidity("Invalid field.");
        });
    },

    signOut: () => {
        auth.signOut().then(function() {
            Router.navigate('/');
        }).catch(function(error) {
            console.error(error.code, error.message);
        });
    }
    
}


Object.freeze(Auth);
export default Auth;