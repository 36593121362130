import './styles/index.scss';

import $ from 'jquery';
import 'popper.js';
import 'bootstrap';
import Papa from 'papaparse';

import Router from './scripts/Router';
import Auth from './scripts/Auth';
import Datatable from './scripts/Datatable';
import { Database } from '@firebase/database';


(function() {
  const authChanged = (user) => {
    if (user) {
      console.log('Signed in');
      // TODO change this
      if (user.email === 'info@in-segno.it') {
        document.getElementById('process-btn').style.display = 'block';
      }
      document.getElementById('signout-btn').style.display = 'block';
      Router.navigate('#dashboard');
      attachListerners();
      Datatable.initDataTable();
      Datatable.read();
    } else {
      console.log('Signed out');
      document.getElementById('process-btn').style.display = 'none';
      document.getElementById('signout-btn').style.display = 'none';
      Router.navigate('#');
      attachListerners();
    }
  };

  const attachListerners = () => {
    // Form validation
    const forms = document.getElementsByClassName('needs-validation');
    Array.prototype.filter.call(forms, function(form) {
      form.addEventListener('submit', function(event) {
        console.log(event);
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
          // TODO
        } else {
          switch (event.target.id) {
            case 'login-form': Auth.signIn(event.target); break;
            case 'contact-form': event.target.submit(); break;
            default: console.log('Submitted form with id #' + event.target.id);
          }
        }

        form.classList.add('was-validated');
      }, false);
    });

    // signout
    const signoutButton = document.getElementById('signout-btn');
    signoutButton.addEventListener('click', Auth.signOut);

    // Contact modal
    $('#contactModal').on('show.bs.modal', function(event) {
      const button = $(event.relatedTarget); // Button that triggered the modal
      const recipient = button.data('emailto'); // Get info from data-* attr
      const modal = $(this);
      modal.find('.modal-body input[name="emailto"]').val(recipient);
      console.log('here');
      // TODO add student name as modal header
    });

    // CSV Upload
    const fileInput = document.querySelector('.custom-file');
    fileInput.addEventListener('change', function(e) {
      $('#csv-loading').show();
      let fileList = e.target.files;
      Papa.parse(fileList[0], papaParseConfig);
      $('#csv-loading').hide();
      $('#csvAddModal').modal('hide');
    }, false);
  };

  const papaParseConfig = {
    delimiter: '', // auto-detect
    newline: '', // auto-detect
    quoteChar: '"',
    escapeChar: '"',
    header: false,
    trimHeader: false,
    dynamicTyping: false,
    preview: 0,
    encoding: '',
    worker: false,
    comments: false,
    step: undefined,
    complete: onParseComplete,
    error: undefined,
    download: false,
    skipEmptyLines: false,
    chunk: undefined,
    fastMode: undefined,
    beforeFirstChunk: undefined,
    withCredentials: undefined,
  };

  /**
   * @param {*} results
   * @param {*} file
   */
  function onParseComplete(results, file) {
    // merge and deduplicates
    // results.data.shift();

    // const dataset = Datatable.dataset;
    // const merged = dataset.concat(results.data);
    // console.log(merged);

    // let newDataset = [];
    // let inArray = false;

    // for (let i = 0; i < merged.length; i++) {
    //   inArray = false;

    //   for (let j = 0; j < dataset.length; j++) {
    //     if (
    //       dataset[j][0] === merged[i][0] &&
    //       dataset[j][1] === merged[i][1] &&
    //       dataset[j][2] === merged[i][2] &&
    //       dataset[j][3] === merged[i][3] &&
    //       dataset[j][4] === merged[i][4]) {
    //       inArray = true;
    //       break;
    //     }
    //   }

    //   if (!inArray) newDataset.push(merged[i]);
    // }
    //
    // for (const newData in newDataset) {
    //   if (newDataset.hasOwnProperty(newData)) {
    //     console.log(newDataset[newData]);
    //     Datatable.write(newDataset[newData]);
    //   }
    // }

    // clear first row
    results.data.shift();

    // remove all data from db
    Datatable.remove();

    for (const data in results.data) {
      if (results.data.hasOwnProperty(data)) {
        Datatable.write(results.data[data]);
      }
    }

    Datatable.clearTable();
    Datatable.read();
  };

  // Router.navigate('#');
  Auth.onAuthStateChanged(authChanged);
})();
