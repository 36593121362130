import {firebase} from '@firebase/app';
import "@firebase/auth";
import "@firebase/database";

// Firebase init
const _firebaseConfig = {
  apiKey: "AIzaSyCowLJAj6ZXnsmahE2MKDOdeXEWROrEV-I",
  authDomain: "insegno-studentsdatatable.firebaseapp.com",
  databaseURL: "https://insegno-studentsdatatable.firebaseio.com",
  projectId: "insegno-studentsdatatable"
};

firebase.initializeApp(_firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.database();
