export const tableView = `
    <div id="table" class="row"> <!-- align-items-center justify-content-center h-100 -->
        <div class="alert alert-info mt-5 mb-5" role="alert">
            Qui puoi trovare un elenco degli studenti che hanno ottenuto la certificazione PSC (Professional Skill Certificate).<br>
            Per contattarli clicca sul pulsante con l'icona della posta.<br><br>
            Clicca sulle intestazioni delle colonne per ordinare per quel campo. Esempio: se clicchi su "cognome" ordinerai per cognome dalla A alla Z. Se clicchi di nuovo su "cognome" ordinerai per cognome dalla Z alla A.
        </div>
        <table id="student-datatable" class="table table-hover table-bordered nowrap">
            <thead class="thead-light">
                <tr>
                <th scope="col">Cognome</th>
                <th scope="col">Nome</th>
                <th scope="col">Nascita</th>
                <th scope="col">Corso</th>
                <th scope="col">Livello</th>
                <th scope="col">Contenuti del Corso</th>
                <th scope="col">Punteggio</th>
                <th scope="col">Data Superamento Esame</th>
                <th scope="col">CV</th>
                <th scope="col">Email</th>
                </tr>
            </thead>
        </table>
    </div>
`;
