import { authView } from "./../templates/authView";
import { tableView } from "./../templates/tableView";

const _routes = {
    '#': authView,
    '#dashboard': tableView
};

let contentDiv = document.getElementById('content');

window.onpopstate = () => {
    contentDiv.innerHTML = _routes[window.location.hash];
}

const Router = {
    navigate: (hash) => {
        window.history.pushState({}, hash, window.location.origin + hash);
        contentDiv.innerHTML = _routes[hash];
    }
}

Object.freeze(Router);
export default Router;