import $ from 'jquery';
import {db} from './Firebase';
import 'datatables.net-bs4';
import 'datatables.net-buttons-bs4';
import 'datatables.net-colreorder-bs4';
import 'datatables.net-fixedheader-bs4';
// import 'datatables.net-responsive-bs4';
import 'datatables.net-select-bs4';

/* Table */
let datatable = null;
let dataset = [];

/**
 * @param {String} dateString
 * @return {String}
 */
function parseDate(dateString) {
  const YYYY = dateString.substring(6, 10);
  const MM = dateString.substring(3, 5);
  const DD = dateString.substring(0, 2);
  const date = new Date(YYYY, MM-1, DD);

  let year = date.getFullYear();
  let month = date.getMonth()+1;
  let day = date.getDate();

  if (day < 10) day = '0' + day;
  if (month < 10) month = '0' + month;

  return day + '/' + month + '/' + year;
}

/**
 * @param {String} dateString
 * @return {String}
 */
function getSortableDate(dateString) {
  const YYYY = dateString.substring(6, 10);
  const MM = dateString.substring(3, 5);
  const DD = dateString.substring(0, 2);
  const date = new Date(YYYY, MM-1, DD);

  let year = date.getFullYear();
  let month = date.getMonth()+1;
  let day = date.getDate();

  if (day < 10) day = '0' + day;
  if (month < 10) month = '0' + month;

  return year + '/' + month + '/' + day;
}

// TODO separate db from datatable
const Datatable = {
  datatable: datatable,
  dataset: dataset,

  initDataTable: () => {
    datatable = $('#student-datatable').DataTable({
      'responsive': false,
      'order': [[7, 'desc']],
      'lengthMenu': [[20, 50, 100, 250, -1], [20, 50, 100, 250, 'Tutti']],
      'columnDefs': [
        {orderable: false, targets: [8, 9]},
      ],
      'language': {
        'url': 'https://cdn.datatables.net/plug-ins/1.10.19/i18n/Italian.json',
      },
    });
  },

  addTableRow: (data, id) => {
    data[2] = '<span hidden>' + getSortableDate(data[2]) + '</span>' + parseDate(data[2]);
    data[5] = '<button type="button" class="btn btn-link" data-toggle="tooltip" data-html="true" title="' + data[5] + '">Vedi competenze</button>';
    data[7] = '<span hidden>' + getSortableDate(data[7]) + '</span>' + parseDate(data[7]);
    data[8] = (data[8] !== '') ? '<a href="' + data[8] + '" role="button" download="cv" target="_blank"><span class="oi oi-data-transfer-download"></span></a>' : '';
    data[9] = '<button class="btn btn-small btn-link" data-toggle="modal" data-target="#contactModal" data-emailto="' + data[9] + '"><span class="oi oi-envelope-closed"></span></button>';
    datatable.row.add(data).node().id = id;
    datatable.draw();


    // Tooltip
    $(function() {
      $('[data-toggle="tooltip"]').tooltip();
    });
  },

  clearTable: () => {
    datatable.clear();
  },

  write: (studentDataArray) => {
    db.ref('students/').push({
      student: studentDataArray,
    });
  },

  read: () => {
    db.ref('students/').once('value').then(function(snapshot) {
      dataset = [];
      let data = snapshot.val();
      for (const student in data) {
        if (data.hasOwnProperty(student)) {
          dataset.push(data[student].student);
          Datatable.addTableRow(data[student].student, student);
        }
      }
    });
  },

  remove: () => {
    db.ref('students/').remove();
  },
};


Object.freeze(Datatable);
export default Datatable;
