const logoImg = require('../assets/icon.png');

export const authView = `
    <div id="login" class="row align-items-center justify-content-center h-100">
        <div class="card">
            <h5 class="card-header text-center">Accedi</h5>
            <img class="card-img-top mt-3" 
                src='${logoImg}' 
                width="100" height="100" 
                alt="inSegno Logo">
            <div class="card-body">
                <form id="login-form" class="needs-validation mt-3" novalidate>
                    <div class="form-group">
                        <label for="user-email">Email address</label>
                        <input type="email" 
                            class="form-control" id="user-email" 
                            aria-describedby="emailHelp" 
                            placeholder="Inserisci indirizzo email" required>
                        <div class="invalid-feedback">
                            <!-- TODO -->
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="user-pass">Password</label>
                        <input type="password" 
                            class="form-control invalidate" 
                            id="user-pass" 
                            aria-describedBy="passHelp" 
                            placeholder="********" required>
                        <div class="invalid-feedback">
                            Email o password errati.
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary mt-2 mr-2">
                        Accedi
                    </button>
                </form>
            </div>
        </div>  
    </div>
`;
